import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import getExcerpt from '../../utils/get-excerpt';
import { getTaxonomyUrl } from '../../utils/urls';
import Button from '../atoms/link-button';
import getGraphqlImage from '../../utils/get-graphql-image';

const FeaturedPost = ({
  subTitle,
  title,
  slug,
  date,
  content,
  excerpt,
  image,
  rootUrl,
  taxonomy,
  categoryName,
  categorySlug,
  excerptLength,
}) => {
  const link = `/${slug}`;
  const generatedExceprt = getExcerpt(content, excerpt, excerptLength);
  const categoryUrl = getTaxonomyUrl(rootUrl, taxonomy, categorySlug);
  const imageSizes = getGraphqlImage(image);

  return (
    <section className="featured-post container">
      <div className="featured-post__inner">
        <span className="featured-post__sub-title">{subTitle}</span>
        <strong className="featured-post__title">
          <Link to={link} dangerouslySetInnerHTML={{ __html: title }} />
        </strong>
        <div className="featured-post__meta">
          {categorySlug && categoryName && (
            <div className="featured-post__categories">
              <Link className="featured-post__category" to={categoryUrl}>
                {categoryName}
              </Link>
            </div>
          )}
          <time className="featured-post__date">{date}</time>
        </div>
        {imageSizes && (
          <Link className="featured-post__image-wrapper" to={link}>
            <Img alt={`${title} image`} className="featured-post__image" fluid={imageSizes} />
          </Link>
        )}
        <div className="featured-post__content">
          {generatedExceprt && (
            <p className="featured-post__excerpt" dangerouslySetInnerHTML={{ __html: generatedExceprt }} />
          )}
          <Button className="featured-post__cta" slug={link}>
            Read more
          </Button>
        </div>
      </div>
    </section>
  );
};

FeaturedPost.defaultProps = {
  excerptLength: 260,
};

export default FeaturedPost;
